<template>
    <y-dropdown
        ref="Dropdown"
        class="grid-sort"
        position="bottom-left"
    >
        <template slot="toggle">
            <y-button :key="generateId('sort')">
                {{ $t('fields.sort') }}
            </y-button>
        </template>

        <div class="grid-sort-form">
            <y-form-field
                v-model="model.sort"
                type="select"
                name="sort"
                :options="sortOptions"
                @input="refresh"
            />

            <y-form-field
                v-model="model.order"
                type="select"
                name="order"
                :options="orderOptions"
                :disabled="!model.sort"
                @input="refresh"
            />
        </div>
    </y-dropdown>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import { YDropdown, YFormField } from '@deps';

    export default {

        name: 'GridHeaderFiltersSort',

        components: {
            YDropdown,
            YFormField,
        },

        props: {
            value     : Object,
            sortFields: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    sort : this.get(this.$route, 'query.sort', 'created_at'),
                    order: this.get(this.$route, 'query.order', 'desc'),
                },
            };
        },

        computed: {

            /**
             * Return sort options
             */
            sortOptions() {
                if (this.sortFields) {
                    return Object.keys(this.sortFields).map((value) => ({
                        label: this.sortFields[value].label,
                        value,
                    }));
                }

                return [];
            },

            /**
             * Order options
             */
            orderOptions() {
                return [
                    {
                        label: this.$t('fields.desc'),
                        value: 'desc',
                    },
                    {
                        label: this.$t('fields.asc'),
                        value: 'asc',
                    },
                ];
            },

        },

        watch: {

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', this.cloneDeep(newVal));
                    }
                },
                deep: true,
            },

        },

        methods: {
            generateId,

            /**
             * Do the refresh of the grid
             */
            refresh() {
                this.$refs.Dropdown.close();
                this.$emit('refresh', {
                    refresh: 'list',
                    sort   : this.model.sort,
                    order  : this.model.order || 'desc',
                });
            },

        },

    };
</script>
